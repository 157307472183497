import React, { useEffect, memo } from 'react';
import PropTypes from 'prop-types';

const TripleseatSection = ({ tripleseatFormId, tripleseatFormKey }) => {
  const src = `<script src="https://api.tripleseat.com/v1/leads/ts_script.js?lead_form_id=${tripleseatFormId}&public_key=${tripleseatFormKey}&inline_form=true"></script>`;
  const id = `tripleseat_embed_form_inline-${tripleseatFormId}`;

  useEffect(() => {
    const createForm = async () => {
      if (tripleseatFormId && tripleseatFormKey) {
        // Dynamic load to get the window ref on client side
        const postscribe = await require('postscribe');
        postscribe(`#${id}`, src);
      }
    };
    createForm();
  }, [id, src, tripleseatFormId, tripleseatFormKey]);

  if (!tripleseatFormId || !tripleseatFormKey) return null;

  return (
    <div id={id} />
  );
};

TripleseatSection.propTypes = {
  tripleseatFormId: PropTypes.number.isRequired,
  tripleseatFormKey: PropTypes.string.isRequired,
};

export default memo(TripleseatSection);
